<template>
  <div>
    <b-container fluid class="bg-login full-height">
      <b-row class="full-height">
        <b-col cols="12" md="6" class="left-side" :style="'background-image: url('+backgroundImage+')'"> </b-col>
        <b-col cols="12" md="6" class="right-side">
          <div class="inner-wrapper" data-anima="right" v-show="!loading">
            <span class="logo mb-5">
              <img v-if="!isGreenn" src="@/assets/img/G-digital-text.png" />
              <img
                v-else
                src="@/assets/greenn_sales/GREENN_SALES_.png"
                style="width: 198px; position: relative; left: -14px"
              />
            </span>

            <div>
              <form @submit.stop.prevent="onSubmit">
                <b-form-group label="Seu e-mail" label-for="email">
                  <b-form-input
                    id="email"
                    placeholder="exemplo@exemplo.com"
                    v-model="email"
                    type="email"
                    name="email"
                    v-validate="'required|email'"
                    tabindex="1"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('email')">
                    Por favor, insira seu e-mail.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label="Senha de acesso"
                  label-for="password"
                  class="pt-4"
                >
                  <div style="position: relative">
                    <b-form-input
                      id="password"
                      placeholder="••••••••"
                      v-model="password"
                      type="password"
                      name="password"
                      v-validate="'required'"
                      tabindex="2"
                      ></b-form-input>
                      <span
                      class="mostrar-senha"
                      ref="mostrar_senha"
                      @click="mostrarSenha"
                      >Mostrar</span
                    >
                  </div>
                  <b-form-invalid-feedback :state="!errors.has('password')">
                    Por favor, insira sua senha
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-invalid-feedback :state="success">
                  E-mail ou senha estão incorretos
                </b-form-invalid-feedback>
                <b-row
                  class="mt-3"
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                  "
                >
                  <router-link
                  tabindex="3"
                    to="/adm/recuperar-senha"
                    class="recupera-senha-text"
                    style="margin-right: 15px"
                    >Esqueci minha senha
                  </router-link>
                </b-row>
                <b-row class="mt-4">
                  <b-overlay
                    :show="loading"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="btn-acoes"
                    :class="{ end: isMobile }"
                  >
                    <BaseButton
                      variant="primary"
                      ref="button"
                      :disabled="loading"
                      type="submit"
                      style="margin-left: 15px"
                      class="btn-login"
                      :class="{ mg: isMobile }"
                      @click="onSubmit"
                      tabindex="4"
                    >
                      Acessar
                    </BaseButton>
                  </b-overlay>
                </b-row>
              </form>
            </div>
          </div>
          <div
            v-if="loading"
            class="py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <!-- modals -->
      <BaseModalSelectBlog :blogs="blogs" @selected="selectBlog" />
    </b-container>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";

import AuthService from "@/services/resources/AuthService";
const service = AuthService.build();
import Cookies from "js-cookie";
const axios = require("axios");
import store from "@/store/index";
//

export default {
  components: {
    BaseView,
  },
  data() {
    return {
      data: {},
      submit: false,
      error: false,
      success: true,
      email: null,
      password: null,
      blogs: [],
      loading: false,
      client: {
        width: 0,
      },
      backgroundImage:'',
    };
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    greennUrl() {
      return process.env.VUE_FRONT_GREENN;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
    onSubmit(rdrk = false) {
      this.submit = true;
      this.loading = true;

      if ((!this.email || !this.password) && !rdrk) {
        this.$bvToast.toast("Preencha os dados corretamente!", {
          title: "Login",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        this.submit = false;
        return;
      }

      var that = this;

      let data = {
        username: this.email,
        password: this.password,
      };

      if (rdrk) {
        data.rdrk = this.$route.query.rdrk;
      }

      if (this.$route.query.authorize) {
        var { authorize, state, redirect_uri } = this.$route.query;
        data.authorize = authorize;
        data.state = state;
        data.redirect_uri = redirect_uri;
      }

      this.$store
        .dispatch("loginRequest", data)
        .then((resp) => {
          that.success = true;
          that.loading = true;
        })
        .catch(() => {
          that.success = false;
          that.loading = false;
          if (authorize) {
            this.$router.push(
              `/adm/login?authorize=${authorize}&redirect_uri=${redirect_uri}&state=${state}`
            );
          }
        });
    },
    selectBlog(blog) {
      this.$store.dispatch("blogSelected", blog);
    },
  },
  mounted() {
    if(this.isGreenn){
      this.backgroundImage = window.location.origin + '/IMAGEM_LOGIN.webp'
    }else{
      this.backgroundImage =  window.location.origin + '/banner1.png'
    }

    if (this.$route.query && this.$route.query.rdrk) {
      Cookies.remove("access_token");
      Cookies.remove("tenantSubdomain");
      Cookies.remove("verify_email");
      localStorage.clear();
      sessionStorage.clear();
      this.onSubmit(true);
    }
    if (Object.keys(this.$route.params).length === 0) {
      this.$router.push({ name: "Login", params: { dynamicRoute: "adm" } });
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.mg {
  margin-right: 15px !important;
  margin-left: 0px !important;
}

.end {
  justify-content: flex-end !important;
}

.text-green {
  color: var(--greenn);
  font-weight: bold;
}

.bg-login {
  background: var(--greenn);
  overflow: hidden;
}

.form-group {
  position: relative;
}

.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px 0px;
  height: 100%;

  .inner-wrapper {
    margin-top: 90px;
    padding: 6% 18%;
    // width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}

.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
  margin-left: 15px;
}

.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}

.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }

  .grecaptcha-badge {
    right: -194px !important;
  }

  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }

  .links-help {
    margin: 0;
  }
}

.full-height {
  height: 100vh;
}

.btn-greenn {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 5px;
  border: 0.5px solid #80808036;
  border-radius: 10px;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 10px;
  cursor: pointer;
}
</style>
